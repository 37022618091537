@charset "UTF-8";
/* Font */
/* Variable */
:root {
  --slate-grey: rgb(103, 103, 112);
  --windows-blue: rgb(49, 120, 172);
  --dark-sky-blue: rgb(56, 150, 219);
  --blue-black: rgb(50, 51, 73);
  --dark: rgb(39, 40, 65);
  --coral: rgb(240, 82, 82);
  --white: rgb(255, 255, 255);
  --pale-grey: rgb(244, 245, 250);
  --s60: 60px;
  --s50: 50px;
  --s40: 40px;
  --s36: 36px;
  --s32: 32px;
  --s30: 30px;
  --s28: 28px;
  --s26: 26px;
  --s24: 24px;
  --s22: 22px;
  --s20: 20px;
  --s18: 18px;
  --s16: 16px;
  --s14: 14px;
  --s12: 12px;
  --light-blue: #E9F0F6;
  --theme-blue: #3178AC;
  --dark-blue: #397096;
  --darker-blue: #214D6C;
  --website-blue: #3B4A5C;
  --theme-red: #E66656;
  --dark-red: #BD5346;
  --theme-yellow: #F5A623;
  --theme-green: #519F4F;
  --theme-brown: #8b5729;
  --odd-row-bg: #f1f1f1;
  --even-row-bg: #FDF6F6;
  --selected-bg: #E9F0F6;
  --lighter-grey: #E7E7E7;
  --light-grey: #ccc;
  --avg-grey: #999;
  --dark-grey: #777;
  --theme-border: solid 1px var(--light-grey);
}

/* Common style */
.title {
  font-family: "Oswald", sans-serif;
  font-size: var(--s36);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: var(--dark);
}

.body {
  font-family: "Lato", sans-serif;
  font-size: var(--s12);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: var(--dark);
}

/*===========================================================
 Common CSS
============================================================*/
body {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #000;
  background: #fff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

ul {
  padding: 0;
  margin: 0;
}

li,
li:hover,
a:hover,
.btn,
.btn:hover {
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none !important;
}

*:active,
*:focus {
  outline: none;
  border: 0;
}

b {
  font-weight: bold;
  color: var(--dark);
}

img {
  max-width: 100%;
}

strong {
  font-weight: 700;
}

u {
  border-bottom: 1px solid #fff;
  line-height: 1;
  text-decoration: unset;
}

.cboth {
  overflow: hidden;
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

button {
  background-color: transparent;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button:active,
button:focus {
  outline: 0;
  outline: 0;
}

table {
  table-layout: fixed;
}

/*===========================================================
 Placeholder Style
============================================================*/
::-webkit-input-placeholder {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.39px;
}

:-moz-placeholder {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.39px;
}

::-moz-placeholder {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.39px;
}

:-ms-input-placeholder {
  color: #6c6c6c;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0.39px;
}

/*===========================================================
Preloader CSS
============================================================*/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 99999999;
}

#preloader-logo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loading-msg {
  width: 100%;
  font-size: 0.75em;
  color: #555;
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
}

.spinner {
  width: 80px;
  height: 80px;
  border: 2px solid #f3f3f3;
  border-top: 3px solid #2489CE;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: spin 1s infinite ease;
  animation: spin 1s infinite ease;
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#loading-msg {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 25px;
  text-align: center;
  color: #333;
  font-size: 0.8em;
}

/*===========================================================

===========================================================*/
.title {
  margin-bottom: 40px;
}

.title h6 {
  font-size: var(--s14);
  font-family: "Lato", sans-serif;
  letter-spacing: 0.5px;
  color: var(--dark);
  margin-bottom: 5px;
  font-weight: 900;
}

.title h2 {
  font-size: var(--s36);
  line-height: 1;
  color: var(--dark);
  font-family: "Oswald", sans-serif;
}

/* Imports */
/*===========================================================
 Hero CSS
===========================================================*/
.page h3 {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.25px;
  color: var(--dark);
}

.page h4 {
  font-family: "Oswald", sans-serif;
  font-size: 16px !important;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--dark);
  margin: 5px 0 0 0 !important;
}

.blog-index-copy h4 {
  font-size: 20px !important;
  line-height: 1.4;
  margin: 0 0 8px 0 !important;
}

.navbar-brand img {
  max-width: 160px;
}

.hero-area {
  /*    background-image: url(/images/2021/bg/hero-bg.png);*/
  width: 100%;
  min-height: 600px;
  background-size: cover;
  background-position: 100% 100%;
  padding-top: 30px;
  position: relative;
  margin-top: 20px;
}

.hero-area-landing {
  background-color: var(--pale-grey);
  width: 100%;
  padding-top: 20px;
  margin-top: 0;
}

.guide-hero-img h4, .responsive-hero h4 {
  text-align: center;
  margin-top: -20px;
  font-weight: 400;
}

.hero-icon {
  width: 40px;
  margin-bottom: 10px;
}

.hero-text {
  padding-top: 40px;
  font-size: var(--s18);
  line-height: 1.3;
  color: var(--dark);
}

.hero-text h1 {
  line-height: 1.2;
  font-size: var(--s50);
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  margin-bottom: 5px;
  max-width: 100%;
}

.hero-text h2 {
  line-height: 1.4;
  font-size: var(--s24);
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  margin-bottom: 15px;
  margin-left: 0;
  max-width: 90%;
  opacity: 0.9;
}

.hero-text p {
  max-width: 90%;
  font-size: var(--s18);
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: var(--dark);
  margin-bottom: 20px;
}

.under-30-minutes {
  color: var(--dark);
  font-weight: bold;
}

/*
.hero-btn {
    display: flex;
    flex-wrap: wrap;
}
*/
.schedule-btn {
  min-width: 230px;
  min-width: 280px;
  width: auto;
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 15px;
}

.schedule-btn:last-child {
  margin-right: 0px;
}

.schedule-btn:first-child span {
  margin-left: 3px;
}

.schedule-btn:first-child img {
  margin-right: 5px;
  display: block;
  height: 24px;
}

.schedule-btn a {
  display: flex;
  align-items: center;
  border-radius: 33px;
  box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.18);
  background-color: var(--coral);
  justify-content: center;
  /*padding: 10px 0;*/
  padding: 10px 20px;
}

.schedule-btn a span {
  display: inline-block;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: var(--s14);
  font-weight: 700;
  line-height: 1.29;
  letter-spacing: 0.5px;
  color: var(--white);
}

.started-btn span span {
  font-size: var(--s12);
}

.schedule-btn a small {
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
}

.schedule-btn.started-btn span,
.schedule-btn.started-btn small {
  color: var(--coral);
}

.schedule-btn.started-btn a {
  color: var(--coral);
  background-color: rgba(255, 255, 255, 0.99);
}

.schedule-a-demo-link {
  margin-left: 10px;
  display: inline-block;
  line-height: 30px !important;
  border-radius: 15px;
  background-color: var(--coral);
  border: 1px solid var(--coral) !important;
  font-size: var(--s16);
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  color: #fff !important;
  padding: 0 12px;
}

.guest-book-link {
  background-color: var(--coral);
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  padding: 8px 16px;
  margin: 10px 0;
  font-weight: 600;
}
.guest-book-link a {
  color: #fff;
  font-weight: bold;
}

.about-ruebarue-title {
  margin-bottom: 20px;
}

.about-ruebarue-title h2 {
  text-align: center;
}

.about-ruebarue {
  padding-bottom: 30px;
  max-width: 767px;
  margin: 40px auto 0 auto;
  font-size: var(--s20);
  color: var(--dark);
  text-align: center;
}

.features-area.home3 {
  background-color: var(--pale-grey);
  padding: 50px 0;
}

.keystone-award {
  padding-bottom: 30px;
  max-width: 767px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 1.7;
  color: var(--dark);
  text-align: center;
}
.keystone-award img {
  max-width: 100%;
  text-align: center;
  margin-top: 20px;
}

.why-ruebarue {
  padding: 50px 0;
  margin-bottom: 40px;
}

.why-ruebarue ul {
  list-style: none;
  padding: 0 40px;
}

.why-ruebarue ul li {
  width: 40%;
  font-size: var(--s18);
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: var(--dark);
  display: inline-block;
  float: left;
  padding: 10px;
  text-indent: -30px;
  margin: 0 30px;
}

.why-ruebarue ul li::before {
  content: "";
  background: url(/images/2021/icons/check.svg) no-repeat;
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  margin-bottom: -0.3125rem;
  margin-right: 0.625rem;
}

/*===========================================================
 Matters
===========================================================*/
.matters-area {
  padding-bottom: 100px;
  margin-top: 30px;
}

.matters-title {
  text-align: center;
  margin-bottom: 50px;
}

.matters-title h6 {
  font-size: var(--s14);
  font-weight: 900;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--dark);
}

.matters-title h2 {
  font-family: "Oswald", sans-serif;
  font-size: var(--s36);
  line-height: 1;
  color: var(--dark);
  margin-top: 7px;
}

.single-matter:nth-child(1) {
  padding-bottom: 80px;
}

.single-matter:nth-child(2) {
  padding-bottom: 100px;
}

.single-matter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.matters-text {
  width: calc(100% - 468px);
}

.matters-img {
  width: 600px;
  background-color: var(--pale-grey);
  height: 460px;
  text-align: center;
  position: relative;
  border-radius: 12px;
  /*box-shadow: -3px 13px 7px 2px #eee;*/
  background-image: url("/images/2021/bg/shadow.png");
  background-size: 100% 100%;
}

.tablet-img {
  width: 100%;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -10px;
}

.matters-text h3 {
  font-family: Oswald;
  font-size: 24px;
  font-family: "Oswald", sans-serif;
  font-size: var(--s24);
  margin-bottom: 10px;
  color: var(--dark);
}

.matters-text p {
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.75;
  color: var(--dark);
}

.pdr-100 {
  padding-right: 140px;
}

.pdl-100 {
  padding-left: 140px;
}

/*===========================================================
Work CSS  
===========================================================*/
.work-area {
  background-color: var(--pale-grey);
  padding: 60px 0;
  margin-bottom: 60px;
}

.single-work {
  padding-right: 50px;
}

.work-img img {
  margin-left: -15px;
}

/*===========================================================
 Features CSS
===========================================================*/
.features-area {
  margin-bottom: 20px;
}

.features-area.home1 {
  background-color: var(--pale-grey);
  padding: 50px 0;
}

.features-area.home1 h2, .features-area.home2 h2 {
  text-align: center;
}

.container.faqs {
  margin: 60px auto;
  padding: 0 10px;
  max-width: 800px;
}

.single-feature {
  margin-bottom: 30px;
}

.feature-icon {
  margin-bottom: 20px;
}

.feature-text h3, .feature-text h3 a {
  font-family: "Oswald", sans-serif;
  font-size: var(--s24);
  font-weight: 500;
  letter-spacing: 0.33px;
  color: var(--dark);
  margin-bottom: 10px;
}

.feature-text p {
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.7;
  color: var(--dark);
}

.learn-more {
  margin-top: 15px;
}

/*===========================================================
 Benefits CSS
===========================================================*/
.benefits-area {
  max-width: 1024px;
  margin: 0 auto;
}

.single-benefit {
  margin-bottom: 20px;
  background: var(--pale-grey) !important;
  padding: 30px;
  height: 270px;
  border-radius: 5px;
}

.benefit-text h3, .benefit-text h3 a {
  font-family: "Oswald", sans-serif;
  font-size: var(--s24);
  font-weight: 500;
  letter-spacing: 0.33px;
  color: var(--dark);
  margin-bottom: 10px;
}

.benefit-text p {
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.7;
  color: var(--dark);
}

#benefits {
  position: relative;
  transition: opacity 150ms ease;
  display: block;
  white-space: normal;
  vertical-align: top;
  overflow: hidden;
  padding: 0px;
  margin: 70px auto 20px auto;
  opacity: 1;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

#benefits h1 {
  max-width: 640px;
  color: #000;
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  width: 80%;
  margin: 10px auto 30px auto;
}
#benefits h2 {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-align: left;
  margin: 20px 0 5px 0;
}
#benefits p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400 !important;
  color: #676770 !important;
}
#benefits .benefits-copy {
  width: 100%;
  max-width: 1024px;
  margin: 30px auto 0 auto;
  padding: 30px 40px;
  background-color: #F4F5FA;
  color: #676770;
  border-radius: 5px;
}
#benefits .hero img {
  display: block;
  max-width: 640px;
  margin: 20px auto;
  border-radius: 5px;
}

/* Blogs page */
#benefits .page {
  width: 1024px;
  margin: 10px auto 20px auto;
  max-width: 100%;
  position: relative;
  transition: opacity 150ms ease;
  display: block;
  white-space: normal;
  vertical-align: top;
  overflow: hidden;
  padding: 0px 10px;
  opacity: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
}
#benefits .benefits {
  background-color: #F4F5FA;
  padding: 20px;
  border-radius: 5px;
  width: 32%;
  display: inline-block;
  vertical-align: top;
  margin: 5px !important;
  height: 580px;
}
#benefits .benefits .benefits-img {
  overflow: hidden;
  margin-bottom: 15px;
  max-height: 240px;
}
#benefits .benefits .benefits-img a {
  display: block;
}
#benefits .benefits .benefits-img a img {
  display: block;
}
#benefits .benefits .benefits-index-copy {
  overflow: hidden;
  margin: 0;
}

/*===========================Testimonial CSS======================*/
.testimonial-area {
  background-image: url(/images/2021/bg/graphic.png);
  width: 100%;
  background-size: cover;
  padding-bottom: 100px;
}

.testimonial {
  position: relative;
  top: 90px;
}

.testimonial-title h2 {
  font-size: var(--s36);
  line-height: 1.25;
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  margin-bottom: 30px;
  text-align: center;
}

.testimonial-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 15px;
  /*background-color: #d9dced;*/
  /*background-image: linear-gradient(283deg, #ffbdbd 231%, #daefff 2%);*/
  padding-bottom: 50px;
  padding-top: 50px;
  background-size: 100% 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.single-testimonial {
  max-width: 33.33%;
  position: relative;
}

.testimonial-icon {
  height: 120px;
  text-align: center;
  vertical-align: middle;
  display: table-cell;
}

.testimonial-icon img {
  max-width: 240px;
  max-height: 120px;
}

.testimonial-text {
  width: 295px;
}

.testimonial-text p {
  font-size: var(--s18);
  line-height: 1.44;
  color: var(--dark);
  padding-left: 20px;
}

.quote-right {
  padding-top: 15px;
}

/*===========================Get Started======================*/
.get-started-area {
  padding-bottom: 30px;
  padding-top: 40px;
  margin: 30px auto;
  max-width: 800px;
  border: 1px solid rgba(240, 82, 82, 0.1);
  background-color: rgba(240, 82, 82, 0.065);
  border-radius: 5px;
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.get-started {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.get-started-left, .get-started-right {
  width: 50%;
}

.get-started-left h2 {
  font-size: var(--s24);
  line-height: 1.3;
  color: var(--coral);
  font-family: "Oswald", sans-serif;
  margin-bottom: 12px;
  text-align: center;
}

.get-started-left p {
  font-size: var(--s26);
  line-height: 1.35;
  color: var(--dark);
}

.get-started-right .schedule-btn {
  margin-right: 0;
  margin-left: 24px;
}

h1 {
  line-height: 1.15;
  font-size: var(--s50);
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  margin-bottom: 20px;
}

.page h1 {
  margin-top: 60px;
}

.page.signup h1 {
  text-align: center;
  font-size: var(--s40) !important;
}

.page h2 {
  margin-top: 30px;
}

a {
  color: var(--coral);
  text-decoration: none;
  font-weight: 400;
}

.page {
  width: 100%;
  margin: 10px auto 20px auto;
  max-width: 1200px;
  position: relative;
  transition: opacity 150ms ease;
  display: block;
  white-space: normal;
  vertical-align: top;
  overflow: hidden;
  padding: 0px 10px;
  opacity: 1;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.25px;
}

.page p {
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.75;
  color: var(--dark);
}

h2 {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: left;
  color: var(--dark);
  width: 1280px;
  margin: 0 auto 0 auto;
  margin-bottom: 10px;
  max-width: 100%;
}

h4 {
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.25px;
  margin-bottom: 10px;
  color: var(--dark);
}

h4 a {
  color: var(--dark) !important;
}

.pricing {
  padding: 10px 20px;
  max-width: 1024px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 10px auto;
  width: 100%;
  background-color: var(--pale-grey) !important;
}

.pricing .row-2 {
  margin-top: 40px;
}

.pricing .row-2 .row-2a {
  float: left;
  width: 50%;
}

.pricing .row-2 .row-2a .period {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.subscription {
  font-size: 28px;
  font-weight: 600;
  display: inline-block;
  color: var(--dark);
  margin-bottom: 5px;
}

input#units {
  margin: 0;
  padding: 0;
}

#units {
  font-size: 24px;
  font-weight: 600;
}

#units, .units {
  border: 0;
  background: none;
  text-align: center;
  font-size: 24px;
  color: #000;
  font-weight: bold;
  margin-bottom: -5px;
}

.slider {
  max-width: 640px;
  margin: 0 auto 20px auto;
}

.view-switches {
  width: 100%;
  margin: 10px 0 20px 0;
  text-align: center;
}

.view-switch {
  display: inline-block;
  vertical-align: middle;
}

.view-switch .switch {
  width: 170px;
  display: inline-block;
  vertical-align: middle;
  padding: 6px;
  text-align: center;
  border: solid 1px #ddd;
  cursor: pointer;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}

.view-switch .switch.active {
  background-color: var(--dark);
  border: solid 1px var(--dark);
  color: white;
}

.view-switch .switch:first-child {
  border-radius: 20px 0px 0px 20px;
}

.view-switch .switch:last-child {
  border-radius: 0px 20px 20px 0px;
}

.pricing-summary {
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 3px;
}

.popular-plan-col {
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  color: #fff;
  height: 36px;
  width: 160px;
  margin: 0 auto;
}

.popular-plan-col.popular {
  background-color: var(--dark);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  color: #fff;
  max-width: 200px;
  z-index: 99;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pricing-cta {
  text-align: center;
  margin-top: 15px;
}

.plan-summary-col {
  text-align: center;
  padding: 15px 10px;
  height: 150px;
  width: 96%;
}

.plan-summary-col .price {
  font-weight: 600;
  font-size: 24px;
  padding: 0 5px;
  letter-spacing: 0;
  line-height: 1;
}

.pricing-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
}

.pricing-plans {
  width: 98%;
  margin: 0 auto;
  display: inline-block;
}

.pricing-col {
  width: 32%;
  float: left;
  margin: 5px;
  border-radius: 3px;
  min-width: 300px;
}

.pricing-title {
  font-family: "Oswald", sans-serif;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: left;
  color: var(--dark);
  text-align: center;
  padding: 15px 10px 5px 10px;
  text-transform: uppercase;
}

.pricing-col ul {
  border-top: 1px solid #ddd;
  padding: 10px 30px 0 30px;
  margin: 0 0 0 0;
  list-style-type: none;
  height: 360px;
  text-align: left;
}

.pricing-col ul li {
  padding: 5px 0;
  text-indent: -30px;
  margin: 0 30px;
}

.pricing-col ul li::before {
  content: "";
  background: url(/images/2021/icons/check.svg) no-repeat;
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  margin-bottom: -0.3125rem;
  margin-right: 0.625rem;
}

.pricing-col ul li:last-child {
  border-bottom: none;
}

.pricing-table {
  background-color: #fff;
  margin: 10px auto 20px auto;
  width: 100%;
  border: 1px solid #ccc;
}

.pricing-table tr {
  border-bottom: 1px solid #ccc;
}

.pricing-table th {
  font-weight: 600;
  text-align: center;
  padding: 15px 10px;
  text-transform: uppercase;
  vertical-align: top;
  padding: 10px;
  border-left: 1px solid #ccc;
}

.pricing-table tr:nth-child(even) {
  background-color: var(--pale-grey);
}

.pricing-table tr td {
  border-left: 1px solid #ccc;
  padding: 10px 15px;
  vertical-align: middle;
  color: var(--dark);
}

.pricing-table tr td:nth-child(1) {
  border-left: none;
}

.pricing-table tr td:not(:first-child) {
  width: 50%;
}

.pricing-overview {
  padding: 0;
  max-width: 800px;
  text-align: left;
  margin: 20px auto;
  width: 100%;
}

.pricing-overview ul {
  list-style: none;
  text-align: left;
}

.pricing-overview ul li {
  min-width: 40%;
  font-size: var(--s16);
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: var(--dark);
  display: inline-block;
  padding: 3px 10px;
  text-indent: -30px;
  margin: 0 30px;
}

.pricing-overview ul li::before {
  content: "";
  background: url(/images/2021/icons/check.svg) no-repeat;
  width: 1.125rem;
  height: 1.125rem;
  display: inline-block;
  margin-bottom: -0.3125rem;
  margin-right: 0.625rem;
}

.helper {
  font-size: var(--s14) !important;
  line-height: 18px !important;
  margin-top: 10px;
}

.roi-calculator {
  padding: 0 20px 20px 20px;
  max-width: 640px;
  text-align: center;
  border-radius: 2px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 20px auto;
  width: 100%;
  background-color: var(--pale-grey) !important;
}

.roi-calculator .row-1b {
  margin: 20px 0;
  width: 100%;
}

.roi-calculator .roi-title {
  float: left;
  width: 65%;
  font-family: "Lato", sans-serif;
  font-size: var(--s14);
  line-height: 1.2;
  color: var(--dark);
  text-align: left;
}

#properties, .properties, #arrivals, .arrivals, #avg-rental-price, .avg-rental-price, #discount, .discount, #conversion, .conversion, #roi, #revenue, #subscription {
  border: 0;
  background: none;
  text-align: right;
  font-size: 14px;
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  margin-bottom: 5px;
  display: inline-block;
  width: 30%;
}

.customers {
  text-align: center;
}

.customers .customer-item {
  width: 33%;
  min-width: 300px;
  max-width: 300px;
}

.customers .customer-item, .customers .case-study-item {
  width: 24%;
  display: inline-block;
  vertical-align: top;
  padding: 10px 18px;
  margin: 3px;
  background-color: #fff;
  display: inline-block;
  margin: 10px 10px 10px 0;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.customers .customer-item a .img, .customers .case-study-item a .img, .case-study-module a .img, .case-study-module .img {
  width: 100%;
  padding-bottom: 46%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  max-width: 200px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
}

.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container.feature {
  padding-top: 75px;
  max-width: 100%;
}

.footer-logo a img {
  max-width: 160px;
  margin-bottom: 40px;
}

.footer-area h3 {
  color: #fff !important;
  margin-top: 0 !important;
}

.tablet-img img {
  height: 380px;
}

.guide-hero-img img {
  max-width: 100%;
}

.row.responsive-hero {
  display: none;
}

.app-icons {
  max-width: 540px;
  text-align: center;
  width: 100%;
  margin: 0 auto 20px auto;
}

.app-icons .app-icon {
  float: left;
  width: 48%;
  padding: 20px;
}

.app-icons .app-icon img {
  max-width: 180px;
  width: 100%;
}

.app-images {
  width: 100%;
  margin: 0 auto;
  max-width: 1024px;
}

.app-images .app-image {
  width: 32%;
  padding: 20px;
  float: left;
}

.app-images .app-image img {
  width: 100%;
  box-shadow: 5px 5px 5px #999;
  border: 1px solid #f2f2f2;
}

.host-login-wrapper {
  height: auto;
  width: auto;
}

.rbr-modal.login-modal {
  width: 640px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  max-width: 100%;
  border: 1px solid #eee;
  border-radius: 3px;
  background-color: var(--pale-grey);
}

.rbr-modal.login-modal .content {
  margin: 12px;
}

.rbr-modal.login-modal h2 {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #000;
  margin: 10px 0px 20px 0;
  text-align: center;
  width: auto;
}

.rbr-modal.login-modal .input-wrapper {
  margin-bottom: 10px;
}

.rbr-modal.login-modal input {
  width: 100%;
  height: 44px;
  padding: 4px 12px;
  font-size: 16px;
  background-color: #fcfcfc;
  color: #666666;
  border: 1px solid #ccc !important;
  border-radius: 2px;
}

.rbr-modal.login-modal .btn {
  border: none;
  height: 44px;
  padding: 10px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 0px;
  max-width: 100%;
  display: block;
  background-color: var(--dark-sky-blue);
  color: white;
  margin-bottom: 20px;
  margin-top: 2px;
}

.rbr-modal.login-modal .errors {
  color: #dd4b39;
  font-size: 14px;
}

.rbr-modal.login-modal .spacer {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin: 10px auto;
  text-align: center;
}

.rbr-modal.login-modal form {
  font-size: 0px;
}

.rbr-modal.login-modal form.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.rbr-modal.login-modal p {
  margin: 0 24px;
  text-align: center;
  margin: 5px 0px;
}

.rbr-modal.login-modal p a {
  color: var(--dark-sky-blue);
  cursor: pointer;
}

.rbr-modal.login-modal .first_name {
  width: 49%;
  display: inline-block;
  margin-right: 1%;
}

.rbr-modal.login-modal .last_name {
  width: 48%;
  display: inline-block;
  margin-left: 1%;
}

.rbr-modal.login-modal form p {
  text-align: left;
}

.rbr-modal.login-modal form .text {
  font-size: 14px;
  color: #666;
}

.rbr-modal.login-modal form .rules {
  list-style-type: disc;
  padding-left: 1.2em;
  font-size: 14px;
}

.rbr-modal.login-modal form .rules .rule {
  font-size: 14px;
  margin: 5px;
}

.rbr-modal.login-modal form .rules .rule.complete {
  text-decoration: line-through;
  color: #519F4F;
}

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 1px solid var(--coral) !important;
  background: var(--coral) !important;
  border-radius: 20px !important;
}

.labels {
  width: 100%;
  margin: 30px 0;
}
.labels .label {
  border: 1px solid #ddd;
  width: auto;
  padding: 5px 15px;
  font-size: 90%;
  border-radius: 3px;
  display: inline;
  margin-right: 5px;
}

.roi-calculator-link {
  border-bottom: 1px solid var(--coral);
  padding-bottom: 2px;
}

.roi-calculator-link a {
  color: var(--coral);
}

.tick-mark {
  margin-right: 10px;
}

.cookieConsentContainer {
  z-index: 999;
  width: 350px;
  min-height: 20px;
  box-sizing: border-box;
  padding: 30px 30px 30px 30px;
  background: #3B4A5C;
  overflow: hidden;
  position: fixed;
  bottom: 30px;
  left: 30px;
  display: none;
}

.cookieConsentContainer .cookieTitle a {
  color: #FFFFFF;
  font-size: 22px;
  line-height: 20px;
  display: block;
}

.cookieConsentContainer .cookieDesc p {
  margin: 0;
  padding: 0;
  color: #FFFFFF;
  display: block;
  margin-top: 10px;
}

.cookieConsentContainer .cookieDesc a {
  color: #FFFFFF;
  text-decoration: underline;
}

.cookieConsentContainer .cookieButton a {
  display: inline-block;
  color: #FFFFFF;
  margin-top: 20px;
  background: #f05252;
  box-sizing: border-box;
  padding: 15px 24px;
  text-align: center;
  transition: background 0.3s;
  border-radius: 5px;
}

.cookieConsentContainer .cookieButton a:hover {
  cursor: pointer;
  background: #3E9B67;
}

.landing-layout #static-header {
  padding: 10px;
  background-color: var(--pale-grey);
}

.landing-layout .hero-area {
  margin-top: 0;
}

.landing-layout .hero-text h1 {
  font-size: var(--s36);
}

.landing-layout h2 {
  font-family: "Oswald", sans-serif;
}

.hero ul {
  padding: 10px 20px;
}

.hero ul li {
  font-size: var(--s18);
  line-height: 1.7;
  color: var(--dark);
}

@media (max-width: 980px) {
  .container.faqs {
    padding: 0 20px;
  }

  .container.faqs .accordion {
    max-width: 100%;
  }

  .features-area.home1 {
    padding: 40px 0 20px;
  }

  .cookieConsentContainer {
    bottom: 0px !important;
    left: 0px !important;
    width: 100% !important;
  }

  .landing #static-header .container {
    padding: 0;
  }

  .landing .hero-area .hero-text {
    padding-top: 0;
  }

  .why-ruebarue ul li {
    width: 100%;
  }
}
/*===========================================================
 Responsive
============================================================*/
@media (min-width: 1300px) {
  .container {
    max-width: 1230px;
  }
}
@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}
@media (max-width: 1300px) {
  .get-started-left {
    max-width: 330px;
  }

  .guide-hero-img {
    z-index: 1;
  }

  .hero {
    position: relative;
    z-index: 10;
  }
}
@media (max-width: 1199px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 0 5px;
    font-size: var(--s12);
  }

  .single-work {
    padding-right: 0;
  }

  .work-area {
    padding: 100px 0;
    margin-bottom: 100px;
  }

  .features-area {
    margin-bottom: 50px;
  }

  .testimonial-inner {
    padding-bottom: 100px;
    padding-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .testimonial-icon {
    top: -135px;
  }

  .testimonial-title {
    padding-bottom: 75px;
  }

  .testimonial-area {
    padding-top: 75px;
  }

  .get-started-area {
    padding-bottom: 60px;
    padding-top: 60px;
  }

  .get-started-left, .get-started-right {
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .get-started-right .schedule-btn.started-btn {
    margin-left: 15px;
  }

  .get-started-right {
    margin-left: auto;
    margin-right: auto;
  }

  .get-started-right .schedule-btn {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .header-area {
    display: none;
  }

  .hero-area, .hero-area-landing {
    position: relative;
    background-image: none;
    background: var(--pale-grey);
    min-height: auto;
    padding-bottom: 50px;
    padding-top: 0;
    margin-top: 0px;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: var(--s16);
    line-height: 2;
  }

  .login-btn a {
    margin-left: 0;
    margin-right: 15px;
    font-size: var(--s16);
  }

  .navbar-nav {
    padding-top: 0;
  }

  .guide-hero-img img {
    width: 100%;
  }

  .guide-hero-img {
    display: none;
    top: unset;
    bottom: -10px;
    width: 100%;
  }

  .row.responsive-hero {
    display: block;
  }

  .hero-area:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    /*        background: var(--pale-grey); */
    opacity: 0.9;
  }

  .guide-hero-img h4, .responsive-hero h4 {
    margin-top: 0;
  }

  .matters-area {
    padding: 50px 0;
  }

  .pdl-100 {
    padding-left: 0;
  }

  .matters-img {
    width: 40%;
    height: 300px;
  }

  .tablet-img img {
    height: 200px;
  }

  .pdr-100 {
    padding-right: 0;
  }

  .matters-text {
    width: 50%;
    margin-top: 20px;
  }

  .mobile-logo {
    width: 100px;
  }

  .dropdown-menu {
    padding-top: 0;
  }

  .menu ul li .dropdown-menu li {
    padding-left: 10px;
  }

  .menu ul li .dropdown-menu li a {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-menu {
    width: 100%;
    background-color: transparent;
    background-clip: padding-box;
    border: none;
    position: unset;
    top: unset;
  }

  main .mobileMenuOpener {
    display: block;
    position: absolute;
    width: 20px;
    height: 100%;
    z-index: 10;
  }

  .menu ul li {
    display: block;
  }

  .menu ul li a {
    color: #fff;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    line-height: 2;
  }

  .menu ul li .dropdown-menu li a {
    line-height: 2;
  }

  .dropdown > a::after {
    right: 10px;
    position: absolute;
    top: 50%;
    margin-top: -1px;
  }

  .single-work {
    margin-bottom: 50px;
  }

  .work-area {
    padding-top: 50px;
    padding-bottom: 0;
    margin-bottom: 50px;
  }

  .feature-icon {
    margin-bottom: 15px;
  }

  .work-text {
    margin-top: -25px;
  }

  .footer-logo {
    margin-bottom: 50px;
  }

  .schedule-btn a small {
    font-size: var(--s12);
  }

  .testimonial-icon {
    position: unset;
    top: unset;
    left: unset;
  }

  .single-testimonial {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    border-radius: 10px;
    background: #fff;
    padding: 25px;
  }

  .testimonial-text {
    width: 100%;
  }

  .testimonial-text .quote-left {
    float: left;
  }

  .testimonial-inner {
    background: none;
    padding: 0;
  }

  .testimonial {
    top: unset;
  }

  .testimonial-area {
    background-image: none;
    background-color: var(--pale-grey);
    padding-top: 50px;
    padding-bottom: 25px;
  }

  .testimonial-title {
    text-align: center;
    padding-bottom: 50px;
  }

  .features-area {
    margin-bottom: 0;
  }

  .features-title {
    text-align: center;
    margin-bottom: 50px;
  }

  .title {
    text-align: left;
    margin-bottom: 20px;
  }

  /*Menu Activation*/
  .panel-header {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    box-shadow: 0px 1px 3px #eee;
    padding: 10px 15px;
  }

  .single-matter:nth-child(2),
.single-matter:nth-child(1) {
    padding-bottom: 50px;
  }

  .get-started-area {
    padding: 30px 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    margin: 0 auto 0 auto;
  }

  .footer-area {
    padding: 50px 0;
  }
}
.accordion {
  max-width: 800px;
}
.accordion .accordion-item {
  border-bottom: 1px solid #ddd;
}
.accordion .accordion-item button[aria-expanded=true] {
  border-bottom: 1px solid #ddd;
}
.accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: var(--dark);
  border: none;
  background: none;
  outline: none;
}
.accordion button:hover, .accordion button:focus {
  cursor: pointer;
}
.accordion button:hover::after, .accordion button:focus::after {
  cursor: pointer;
  border: 1px solid var(--dark-sky-blue);
}
.accordion button .accordion-title {
  padding-right: 30px;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: var(--s20);
  color: var(--dark);
}
.accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 24px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}
.accordion button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}
.accordion button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}
.accordion button[aria-expanded=true] {
  color: var(--coral);
}
.accordion button[aria-expanded=true] .icon::after {
  width: 0;
}
.accordion button[aria-expanded=true] + .accordion-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 200ms linear, max-height 200ms linear;
  will-change: opacity, max-height;
}
.accordion .accordion-content p {
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.7;
  color: var(--dark);
  margin: 15px 0;
}

@media (max-width: 767px) {
  .testimonial-title h2 {
    margin-bottom: 0;
  }

  .matters-img {
    width: 40%;
    height: 250px;
  }

  .tablet-img img {
    height: 170px;
  }

  .matters-text {
    width: 55%;
  }

  .get-started-area .hero-btn {
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
  }

  .get-started-right .schedule-btn.started-btn {
    margin-left: 0;
    margin-top: 15px;
  }

  .get-started-left p {
    font-size: var(--s18);
  }

  .footer-logo {
    margin-bottom: 35px;
  }

  .single-footer {
    margin-bottom: 30px;
  }

  .single-footer h3 {
    margin-bottom: 10px;
  }

  .footer-area {
    padding-bottom: 20px;
  }

  .social-icons {
    margin-top: 30px;
  }

  .pricing-table tr td {
    padding: 10px;
  }

  .subscription {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .matters-title h2, .title h2, .testimonial-title h2 {
    font-size: var(--s30);
    line-height: 1.2;
    margin-top: 0;
  }

  .hero-text h1, .page h1 {
    font-size: var(--s36);
    margin-bottom: 10px;
  }

  .matters-img {
    width: 100%;
    height: 250px;
  }

  .tablet-img img {
    height: 170px;
  }

  .matters-text {
    width: 100%;
  }

  .single-matter {
    flex-wrap: wrap;
  }

  .matters-img {
    border-radius: 0;
  }

  .hero-area .hero-btn .schedule-btn {
    max-width: 320px;
  }
  .hero-area .hero-btn .schedule-btn .img {
    display: none;
  }

  .hero-btn {
    flex-direction: column;
    text-align: center;
  }

  .get-started-area .schedule-btn {
    width: 100%;
  }

  .schedule-btn {
    margin-top: 15px;
  }

  .hero-text p {
    margin-bottom: 20px;
  }
}
@media (max-width: 374px) {
  .schedule-btn a {
    border-radius: 43px;
    margin: 0 10px;
  }

  .hero-text h1, .page h1 {
    font-size: var(--s36);
  }
}
/*===========================================================
 Scroll To Top
============================================================*/
.go-top {
  bottom: 15px;
  display: none;
  position: fixed;
  right: 15px;
  z-index: 999;
}

.go-top img {
  width: 35px;
}

.go-top span {
  /*background-color: #4285F4;*/
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 10px;
}

.go-top span:hover {
  color: #fff;
}

/*===========================================================
 Navigation Menu Desktop
============================================================*/
.header-area {
  padding: 15px 0;
}

.header {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.menu ul li {
  list-style: none;
  display: inline-block;
}

.menu ul li a {
  color: #3e3e3e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
}

.menu ul li a:hover {
  color: #d25419;
}

.logo img {
  width: 150px;
}

/*===========================================================
 Navigation Menu slideout
============================================================*/
main .mobileMenuOpener {
  display: none;
}

.panel-header {
  display: none;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  background-color: #FFF;
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.slideout-open .slideout-menu {
  background: #34495e;
}

.btn-hamburger {
  cursor: pointer;
}

.menu-section-list {
  padding: 25px 10px;
}

.menu-section-list li a:hover {
  color: #bdc3c7;
}

.menu-section-list li a {
  line-height: 2;
  color: #fff;
}

.menu-section-list li {
  display: block;
}

.btn-hamburger img {
  width: 27px;
}

/*Hamburger Menu Icon*/
.hamburger .line {
  width: 40px;
  height: 5px;
  background-color: #34495e;
  display: block;
  margin: 8px auto;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  opacity: 0.8;
}

.slideout-open .line:nth-child(2) {
  opacity: 0;
}

.slideout-open .line:nth-child(1) {
  transform: translateY(13px) rotate(45deg);
}

.slideout-open .line:nth-child(3) {
  transform: translateY(-13px) rotate(-45deg);
}

.menu ul li .dropdown-menu li {
  display: block;
}

.menu ul li .dropdown-menu li a {
  padding: 5px 15px;
  line-height: 1.2;
  display: block;
}

.dropdown-menu {
  margin: 0;
  border: 0;
  border-radius: 0;
}

.dropdown a {
  position: relative;
}

.dropdown > a:hover::after {
  color: #d25419;
}

.dropdown > a::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: middle;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.contact-by-phone {
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-bottom: 4px;
  font-size: var(--s14);
  font-weight: 500;
  padding-right: 10px;
}

.contact-by-phone .label {
  color: var(--dark);
  margin-right: 3px;
}

.contact-by-phone .text {
  color: var(--dark);
}

.contact-by-phone-footer {
  margin-top: 20px;
}

.contact-by-phone-footer .label, .contact-by-phone-footer .text {
  color: #fff;
  margin-right: 3px;
}

.contact-by-phone-footer a {
  color: #fff;
}

.feature-of {
  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.6;
  font-weight: 600;
}

/*===========================================================
 Menu
===========================================================*/
.navigation-area {
  padding: 5px;
  /*    position: fixed;
      top: 0;
      left: 0;
  */
  width: 100%;
  z-index: 999;
  /* background: var(--pale-grey) !important; */
}
.navigation-area .navbar-dropdown-content {
  display: flex;
  align-items: center;
  flex: 1;
}
.navigation-area .navbar-dropdown-content .navbar-nav {
  margin-left: 0 !important;
  margin-top: 7px;
}
.navigation-area .navbar-dropdown-content .login-btn.form-inline {
  margin-left: auto;
  margin-top: 7px !important;
}
@media screen and (min-width: 992px) {
  .navigation-area .container {
    max-width: 100%;
  }
}
.navigation-area .promo-bar {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #f2f2f2;
  color: var(--dark);
  padding: 6px;
  margin: 0;
  font-size: 15px;
  margin-bottom: 5px;
  letter-spacing: 0.5px;
}
.navigation-area .promo-bar a {
  color: var(--dark);
  text-decoration: underline;
}
@media screen and (max-width: 991px) {
  .navigation-area .container {
    max-width: 100%;
    padding: 0px;
  }
  .navigation-area .container .navbar-branding {
    flex: 1;
    max-width: 100%;
    margin: 0px auto;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  .navigation-area .container .navbar-dropdown {
    padding: 16px;
    box-shadow: 0px 17px 10px -10px #5f5f5f;
    background: var(--pale-grey);
    background: -webkit-linear-gradient(top, var(--pale-grey), #dedede);
    background: -moz-linear-gradient(top, var(--pale-grey), #dedede);
    background: linear-gradient(to bottom, var(--pale-grey), #dedede);
    text-align: left;
    padding: 0px;
  }
  .navigation-area .container .navbar-dropdown .navbar-dropdown-content {
    display: block;
    width: 720px;
    max-width: 100%;
    margin: 0px auto;
    padding: 0px 6px;
  }
  .navigation-area .container .navbar-dropdown .navbar-dropdown-content .form-inline {
    padding: 16px 0px;
  }
}

.navbar-light .navbar-nav .nav-item {
  position: relative;
  padding: 8px 0;
}
.navbar-light .navbar-nav .nav-item .nav-link {
  font-size: var(--s16);
  font-weight: 400;
  line-height: 1;
  color: var(--dark);
  padding: 0 8px;
}
.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: var(--dark-sky-blue);
}
.navbar-light .navbar-nav .nav-item .nav-hover {
  position: absolute;
  top: 100%;
  transform: translateX(-30%);
  padding: 10px 10px 0 10px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms ease;
  z-index: 1000;
  left: 120px;
}
.navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content {
  width: 480px;
  box-shadow: 0px 0px 30px 0px #aaa;
  padding: 6px 0px;
  border-radius: 5px;
  background-color: var(--pale-grey);
  position: relative;
  /* margin-left: 275px;*/
}
.navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content.two-column {
  width: 800px;
}
.navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content.two-column ul li {
  width: 50%;
}
.navbar-light .navbar-nav .nav-item .nav-hover .arrow.up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 11px solid white;
  position: absolute;
  bottom: 100%;
  left: 0;
  margin-left: 25px;
  margin-bottom: -1px;
}
.navbar-light .navbar-nav .nav-item .nav-hover h4 {
  color: #bababe;
  font-weight: 700;
  font-size: 16px;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul {
  margin: 0px;
  padding: 0px 10px;
  list-style-type: none;
  font-size: 0px;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li {
  display: inline-block;
  margin: 0px;
  width: 100%;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li span {
  font-size: var(--s16);
  color: var(--dark);
  display: inline-block;
  vertical-align: middle;
  line-height: 1.3;
  display: inherit;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature {
  display: flex;
  background-color: #fff;
  padding: 10px;
  margin: 6px 6px 3px 6px;
  border-radius: 5px;
  align-items: baseline;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature .feature-image {
  display: inline-flex;
  margin-right: 12px;
  align-self: flex-start;
  margin-top: 3px;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature .feature-image img {
  max-width: 36px;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature .feature-title {
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-size: var(--s18);
  color: var(--dark);
  display: inline-block;
  vertical-align: middle;
  font-weight: 500;
  margin-bottom: 3px;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature .feature-title:hover {
  color: var(--dark-sky-blue);
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .feature .navbar-light .navbar-nav .nav-item .nav-hover ul li span .feature-of {
  font-size: 14px !important;
  text-transform: uppercase !important;
  opacity: 0.8 !important;
}
.navbar-light .navbar-nav .nav-item .nav-hover ul li .new-feature {
  margin-left: 4px;
  margin-top: -3px;
}
.navbar-light .navbar-nav .nav-item:hover .nav-hover {
  opacity: 1;
  pointer-events: all;
}
@media screen and (max-width: 991px) {
  .navbar-light .navbar-nav .nav-item .nav-hover {
    display: block;
    opacity: 1;
    position: relative;
    width: 100%;
    transform: none;
    padding: 0;
    left: 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content {
    width: auto;
    box-shadow: none;
    padding: 0px;
    background-color: transparent;
    margin-top: 10px;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content .arrow.up {
    display: none;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li {
    display: block;
    width: 100%;
    margin: 0px;
    padding: 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li .feature {
    margin: 8px 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li:last-child {
    padding-bottom: 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a .feature {
    background: none;
    padding: 0;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a .feature .feature-image {
    display: none;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a .feature .feature-title {
    font-weight: normal;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a .feature .feature-intro {
    margin-left: 10px;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a .feature .feature-intro span {
    display: none;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a span {
    font-size: var(--s16);
    font-weight: 400;
    line-height: 1;
    color: var(--dark);
    padding: 0 11px;
  }
  .navbar-light .navbar-nav .nav-item .nav-hover .nav-hover-content ul li a span:hover {
    color: var(--dark-sky-blue);
  }
}

/*
.login-btn.login:hover {
    color: var(--white);
    background: var(--dark-sky-blue);
    border: 1px solid transparent;
}
*/
.login-btn a {
  margin-left: 10px;
  margin-right: 0px;
  display: inline-block;
  line-height: 30px !important;
  border-radius: 15px;
  /* border: 1px solid #ccc; */
  font-size: var(--s16);
  line-height: 1;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--dark-sky-blue);
  padding: 0 12px;
}

.login-btn a.login {
  background-color: #fff;
  color: var(--dark);
  padding: 0 5px;
}

.login-btn a.login:hover {
  color: var(--dark-sky-blue);
}

.sign-up {
  background-color: var(--dark-sky-blue);
  color: #ffffff !important;
}

/* Modals */
body.modal-active.login-modal #login-modal {
  opacity: 1;
  z-index: 9999;
  pointer-events: all;
}
body.modal-active.reset-modal #reset-modal {
  opacity: 1;
  z-index: 9999;
  pointer-events: all;
}

body.menu-active #static-header .menu .links {
  opacity: 1;
  height: 245px;
}
body.menu-active #static-header .menu .links.logged-in {
  height: 300px;
}

.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  transition: opacity 500ms ease-out;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.2);
  transform: translateZ(0);
}
.modal-wrapper.active {
  z-index: 9999;
  opacity: 1;
  pointer-events: all;
}
.modal-wrapper .rbr-modal {
  padding: 18px;
  position: absolute;
  left: 50%;
  top: 0px;
  opacity: 0;
  background-color: white;
  transition: top 500ms ease-out, opacity 500ms ease-out;
  transform: translateZ(0);
  width: 450px;
  margin-left: -200px;
}
@media screen and (max-width: 767px) {
  .modal-wrapper .rbr-modal {
    width: 90%;
    margin-left: -45%;
  }
}
.modal-wrapper .rbr-modal .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 26px;
}
.modal-wrapper .rbr-modal.active {
  top: 50px;
  opacity: 1;
}

.modal-wrapper form.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.modal-wrapper .input-wrapper {
  margin-top: 10px;
}
.modal-wrapper .input-wrapper input[type=text], .modal-wrapper .input-wrapper input[type=password] {
  width: 100%;
  height: 44px;
  margin: 3px 0px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 400;
  background-color: #fcfcfc;
  color: #666666;
  border: 1px solid #ccc !important;
  border-radius: 3px;
}
.modal-wrapper .input-wrapper input[type=submit] {
  height: 44px;
  padding: 10px;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 0px;
  max-width: 100%;
  display: block;
  background-color: var(--dark-sky-blue);
  color: white;
  margin-bottom: 20px;
  margin-top: 2px;
}
.modal-wrapper .reset-btn {
  color: var(--theme-blue);
  font-weight: 500;
}
.modal-wrapper .rbr-modal {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 3px;
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}
.modal-wrapper .rbr-modal h1 {
  text-align: center;
  font-family: "Oswald", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
  color: #000;
  width: auto;
}
.modal-wrapper .rbr-modal a {
  color: #E56666;
  font-weight: 400;
}
.modal-wrapper .rbr-modal p.spacer {
  text-transform: uppercase;
  margin: 10px auto 0px auto;
  display: block;
  text-align: center;
}
.modal-wrapper .rbr-modal .errors {
  color: #dd4b39;
}
.modal-wrapper i.close-login-btn {
  padding: 5px;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.modal-wrapper .facebook-login, .modal-wrapper .gplus-login, .modal-wrapper .twitter-login {
  border-radius: 3px;
  text-transform: uppercase;
}

#login-modal.modal-wrapper p.msg {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 0px;
}

.login-modal .btn, .reset-modal .btn {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
  .login-modal .rbr-modal.active .content {
    margin: 12px 0px;
  }
}

/*===========================================================
 Footer Area
===========================================================*/
.footer-area {
  background-color: var(--dark);
  padding-top: 60px;
  padding-bottom: 60px;
}

.single-footer h3 {
  font-size: var(--s18);
  font-weight: 500;
  /*font-family: 'Oswald', sans-serif;*/
  color: var(--white);
  margin-bottom: 10px;
  text-transform: uppercase;
}

.footer-menu ul li {
  list-style: none;
}

.footer-menu ul li a {
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 2.13;
  color: var(--white);
}

.copyright p {
  font-size: 13px;
  line-height: 2.62;
  letter-spacing: normal;
  color: var(--white);
}

.social-icons {
  margin-top: 50px;
  margin-bottom: 10px;
}

.social-icons a {
  display: inline-block;
  margin-right: 15px;
}

#blog {
  position: relative;
  transition: opacity 150ms ease;
  display: block;
  white-space: normal;
  vertical-align: top;
  overflow: hidden;
  padding: 0px;
  margin: 70px auto 20px auto;
  opacity: 1;
  font-size: var(--s16);
  line-height: 1.7;
  color: var(--dark);
  font-weight: 400;
}

#blog h1 {
  max-width: 640px;
  color: var(--dark);
  font-size: var(--s36);
  line-height: 1.2;
  font-weight: 500;
  text-align: center;
  width: 80%;
  margin: 10px auto 30px auto;
}

#blog h2 {
  font-size: var(--s30);
  line-height: 1.2;
  font-weight: 500;
}

#blog h3 {
  font-size: var(--s22);
  line-height: 1.2;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: var(--dark);
  margin: 20px 0 10px 0;
  opacity: 0.9;
}

#blog h4 {
  font-size: var(--s18);
  line-height: 1.2;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: var(--dark);
  margin: 20px 0 10px 0;
  opacity: 0.9;
}

.page h1 {
  margin-top: 60px !important;
}

#blog .hero img {
  display: block;
  max-width: 640px;
  margin: 20px auto;
  border-radius: 5px;
}

#blog .cta {
  max-width: 640px;
  text-align: center;
  margin: 20px auto;
  width: 100%;
}

#blog .cta img {
  border-radius: 5px;
  border: 1px solid #f2f2f2;
}

#blog .blog-copy {
  width: 100%;
  max-width: 800px;
  margin: 20px auto 0 auto;
  padding: 30px 40px;
  background-color: #F4F5FA;
  color: var(--dark);
  border-radius: 5px;
}

#blog p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400 !important;
  color: var(--dark) !important;
  margin-bottom: 20px;
}

.blog-copy b, .blog-copy strong {
  color: #000;
}

.blog-copy a, .blog-copy a {
  color: var(--coral);
}

.blog-copy b a, .blog-copy strong a {
  color: var(--coral);
  font-weight: 600;
}

.blog-copy .hero img {
  display: block;
  width: 100%;
  max-width: 640px;
  margin: 20px auto;
  border-radius: 5px;
}

p.photo-credit {
  font-size: 80% !important;
  text-align: center;
  margin: -10px auto 0 auto;
  max-width: 480px;
  line-height: 18px !important;
}

.page.blog-index p {
  margin-bottom: 10px;
  font-family: "Lato", sans-serif;
  font-size: var(--s16);
  line-height: 1.7;
  color: var(--dark);
  font-weight: 400;
}
.page.blog-index .blog-copy {
  width: 100%;
  max-width: 1024px;
  margin: 30px auto 0 auto;
  padding: 30px 40px;
  background-color: #F4F5FA;
  border-radius: 5px;
}
.page.blog-index .hero img {
  display: block;
  max-width: 640px;
  margin: 20px auto;
  border-radius: 5px;
}

.blog-copy ul {
  margin: 0 0 20px 0;
  padding-left: 20px;
}

/* Blogs page */
.page.blog-index h2 {
  font-size: var(--s36);
  line-height: 1;
  color: var(--dark);
  font-family: "Oswald", sans-serif;
  text-align: center;
  margin-bottom: 30px;
}
.page.blog-index .blog {
  background-color: #fff;
  border-radius: 5px;
  width: 31%;
  display: inline-block;
  vertical-align: top;
  margin: 10px !important;
  height: 600px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.page.blog-index .blog .blog-img {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  min-height: 200px;
  overflow: hidden;
}
.page.blog-index .blog .blog-img a {
  display: block;
}
.page.blog-index .blog .blog-img a img {
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.page.blog-index .blog .blog-index-copy {
  overflow: hidden;
  margin: 0;
  padding: 10px 20px;
}
.page.blog-index .blog .blog-index-copy h4 {
  min-height: 52px;
}

.row.blog.homepage-posts {
  height: 320px;
}

.webinar {
  font-size: var(--s16);
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: var(--dark);
  border: 1px solid #ccc;
  background-color: #F4F5FA;
  padding: 20px;
  width: 100%;
  max-width: 640px;
  margin: 20px auto 20px auto;
  text-align: center;
  border-radius: 5px;
}

.webinar h2 {
  margin-top: 10px;
  text-align: center;
  font-size: var(--s24) !important;
}

.webinar-row h2 {
  text-align: center;
  margin-bottom: 20px;
}

.webinar-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  margin: 5px 0 15px 0;
  color: var(--dark);
  font-family: "Oswald";
}

.webinar-sub-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  margin: 10px 0 0 0;
  color: var(--dark);
  font-family: "Oswald";
  opacity: 0.8;
}

.webinar-date {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  margin: 0 0 5px 0;
  color: var(--dark);
  font-family: "Oswald";
}

.webinar-left {
  width: 27%;
  float: left;
  text-align: right;
  margin-right: 10px;
}

.webinar-right {
  text-align: left;
}

.webinar-row {
  margin-bottom: 10px;
}

.webinar .webinar-signup-button {
  background-color: var(--dark-sky-blue);
  font-size: var(--s14);
  line-height: 1;
  letter-spacing: 0.5px;
  margin-top: 10px;
  padding: 12px 20px 10px 20px;
  text-align: center;
  border-radius: 3px;
  display: inline-block;
}

.webinar .webinar-signup-button a {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.tickmarks {
  list-style: none;
}

.tickmarks li:before {
  content: "✓ ";
}

.divTable {
  display: table;
  width: 100%;
}

.divTableRow {
  display: table-row;
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
}

.divTableCell, .divTableHead {
  border: 1px solid #ccc;
  display: table-cell;
  padding: 3px 10px;
  border-right: 0;
  border-bottom: 0;
}

.divTableHead:last-child, .divTableCell:last-child {
  border-right: 1px solid #ccc;
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

.divTableBody {
  display: table-row-group;
}

.divTableCell:first-child {
  font-weight: bold;
  color: var(--dark);
}

.divTableHead {
  font-weight: bold;
  color: var(--dark);
  text-align: center;
}

p.blog-about-ruebarue {
  padding: 15px 20px;
  border-left: 3px solid var(--coral);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff;
}

.mailchimp-container {
  width: 100%;
  margin: 20px auto 30px auto;
  max-width: 800px;
  background-color: var(--pale-grey);
  padding: 40px 40px 20px 40px;
  border-radius: 5px;
}

#mc_embed_signup form {
  padding: 0 !important;
}

#mc_embed_signup .button {
  background-color: var(--coral) !important;
}

#mc_embed_signup div#mce-responses {
  margin: 0 !important;
  padding: 0 !important;
}

#mc_embed_signup #mce-success-response {
  padding: 0 !important;
}

#mc_embed_signup .mc-field-group {
  padding-bottom: 10px !important;
}

@media (max-width: 767px) {
  .blog {
    width: 94% !important;
    height: auto !important;
  }

  #blog .blog-copy {
    margin: 0;
    padding: 20px;
  }

  #blog h1 {
    font-size: 36px;
    line-height: 46px;
  }

  #blog .col-1 {
    width: 40px;
    padding: 15px 0;
  }

  #blog .col-1 img {
    height: 40px !important;
  }

  .webinar {
    margin-top: 60px;
    border: none;
  }

  .webinar-left {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }

  .webinar-right {
    text-align: center;
  }
}